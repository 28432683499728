export const headerTitles = {
    "/app/manage-organization": "Organizations",
    "/app/add-organization": "Create Organization",
    "/app/manage-roles": "Roles",
    "/app/create-role": "Create Role",
    "/app/create-individual": "Create Individual",
    "/app/manage-individuals": "User Management",
    "/app/create-program": "Create Program",
    "/app/manage-program": "Programs",
    "/app/manage-category": "Question Categories",
    "/app/create-category": "Create Question Category",
    "/app/manage-questions": "Questions",
    "/app/create-questions": "Create Question",
    "/app/manage-questionnaire": "Questionnaires",
    "/app/create-questionnaire": "Create Questionnaire",
    "/app/manage-surveys": "Surveys",
    "/app/create-surveys": "Create Survey Questionnaire",
    "/app/manage-surveys-results": "Survey Results",
    "/app/manage-notifications": "Manage Notifications",
    "/app/user-notifications": "Notifications",
    "/app/CreateNotification": "Create Notification",
    "/app/hcn-list": "HCN Authority",
    "/app/add-hcn": "Add HCN",
    "/app/manage-schedule-category": "Schedule Categories",
    "/app/add-schedule": "Create Schedule Category",
    "/app/locations": "Locations",
    "/app/identities/devices": "Device Management",
    "/app/create-device" : "Create Device",
    "/app/add-clinic-location": "Create Clinic Location",
    "/app/manage-groups": "Groups",
    "/app/add-group": "Create Group",
    "/app/group-patients-listing": "Users List",
    "/app/manage-queue": "Queue",
    "/app/waiting-room": "Waiting-Room",
    "/app/documents": "Documents",
    "/app/add-documents": "Add Document",
    "/app/manage-sft": "Secure File Transfer",
    "/app/appointment": "Appointments",
    "/app/providers-schedule-appointments": "Practitioner Management",
    "/app/threshold-groups": "Threshold Groups",
    "/app/threshold-patient-list": "Patients List",
    "/app/dashboard" : "My Dashboard",
    "/app/protocol-assessment": "Protocol Assessment",
    "/app/medication-request" : "Add Prescription",
    "/app/create-condition" : "Add Condition",
    "/app/edit-condition" : "Edit Condition",
    "/app/surgeries-screening" : "Add Surgeries/Screening",
    "/app/edit-surgeries-screening" : "Edit Surgeries/Screening",
    "/app/create-familyHistory" : "Add Family History",
    "/app/edit-familyHistory" : "Edit Family History",
    "/app/create-observation" : "Add Observation",
    "/app/edit-observation" : "Edit Observation",
    "/app/patients-details" : "Patient Dashboard",
    "/app/encounter-details" : "Encounter Details",
    "/app/user-profile" : "Profile",
    "/app/schedule" : "Manage Schedule",
    "/app/create-schedule" : "Create Schedule",
    "/app/edit-schedule" : "Edit Schedule",
    "/app/change-password" : "Change Password",
    "/app/document-dashboard" : "Document Dashboard",
    "/app/upload-document" : "Upload Document",
    "/app/resources-hub" : "Resource Hub",
    "/app/identities/employees": "Employee Management",
    "/app/identities/patients": "Patient Management",
    "/app/create-employee": "Create Employee",
    "/app/create-patient": "Create Patient",
    "/app/identities/super-admins": "Admin Management",
    "/app/create-admin": "Create Admin",

  }